import { PaletteColor, ThemeOptions } from "@mui/material";

const primaryColor: PaletteColor = {
  main: "#1877F2",
  light: "#73BAFB",
  dark: "#0C44AE",
  contrastText: "#FFFFFF",
};

const secondaryColor: PaletteColor = {
  main: "#28D47B",
  light: "#66e0a1",
  dark: "#059249",
  contrastText: "#FFFFFF",
};

const themeOptions = (): ThemeOptions => {
  return {
    palette: {
      primary: primaryColor,
      secondary: secondaryColor,
      contrastThreshold: 4.5,
      background: {
        default: "#f8f8f8",
        paper: "#f8f8f8",
      },
    },
    spacing: 5,
    shape: {
      borderRadius: 8,
    },
    typography: {
      fontFamily: ["Inter", "sans-serif"].join(","),
      button: {
        textTransform: "none",
      },
    },
    components: {
      MuiCssBaseline: {
        styleOverrides: {
          body: {
            "&::-webkit-scrollbar, & *::-webkit-scrollbar": {
              backgroundColor: "#F2F2F6",
              borderRadius: 8,
              // scrollbarWidth: 'thin'
              width: ".5rem",
            },
            "&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb": {
              borderRadius: 8,
              backgroundColor: "#dbdbdb",
            },
            "&::-webkit-scrollbar-thumb:focus, & *::-webkit-scrollbar-thumb:focus":
              {
                backgroundColor: "#959595",
              },
            "&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover":
              {
                backgroundColor: "#959595",
              },
            "&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner": {
              backgroundColor: "#F2F2F6",
            },
          },
        },
      },
      MuiTypography: {
        defaultProps: {
          variantMapping: {
            h1: "h2",
            h2: "h2",
            h3: "h2",
            h4: "h2",
            h5: "h2",
            h6: "h2",
            subtitle1: "span",
            subtitle2: "span",
            body1: "span",
            body2: "span",
          },
        },
      },
    },
  };
};

export default themeOptions;
