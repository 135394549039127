import { TextField } from "@mui/material";
import React, { FC } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { CustomProps, IFormInputProps } from "./models";
import { IMaskInput } from "react-imask";

const FormTextInput = React.forwardRef<any, CustomProps>(function FormTextInput(
  props,
  ref
) {
  const { onChange, ...other } = props;
  return (
    <IMaskInput
      {...other}
      inputRef={ref}
      unmask={props.unmask}
      onChange={() => {}}
      onAccept={(value: string) =>
        onChange({ target: { name: props.name, value } })
      }
    />
  );
});

export const FormInput: FC<IFormInputProps> = ({
  name,
  mask,
  definitions,
  overwrite,
  unmask,
  startAdornment,
  endAdornment,
  ...otherProps
}) => {
  const formContext = useFormContext();

  if (formContext === null) {
    throw new Error("No FormContext provided");
  }

  const { control } = formContext;

  return (
    <Controller
      control={control}
      name={name}
      defaultValue={""}
      render={({ fieldState: { error }, field }) => {
        const inputProps = {
          inputComponent: FormTextInput,
          inputProps: {
            mask: mask,
            unmask: unmask,
            definitions: definitions,
          },
        };

        return (
          <TextField
            {...field}
            {...otherProps}
            onChange={field.onChange}
            value={field.value || ""}
            error={!!error}
            ref={field.ref}
            InputProps={
              (mask && inputProps) || {
                startAdornment: startAdornment,
                endAdornment: endAdornment,
              }
            }
            helperText={error ? error.message : ""}
          />
        );
      }}
    />
  );
};
