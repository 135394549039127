import React, { useContext } from "react";

import { createContext, useCallback, useState } from "react";

import {
  INotification,
  INotificationContext,
  INotificationContextProviderProps,
} from "./models";
import { Notification } from "../../Components/UI/Notification";

export const NotificationContext = createContext<INotificationContext>({
  setMessage: () => {},
  closeNotification: () => {},
});

/**
 *
 * Notification provider for children utilize notification context
 *
 * @param param notification props
 * @returns the context
 */
export const NotificationProvider: React.FC<
  INotificationContextProviderProps
> = ({ children, notificationDuration }) => {
  const [notification, setNotification] = useState<INotification>();
  const [open, setOpen] = useState(false);

  const handleSetMessage = useCallback((notification: INotification) => {
    setNotification(notification);
    setOpen(true);
  }, []);

  const closeNotification = useCallback(() => {
    setOpen(false);
    setNotification(undefined);
  }, []);

  return (
    <>
      {notification && (
        <Notification
          closeNotification={closeNotification}
          message={notification.message}
          type={notification.type}
          notificationKey={notification.notificationKey}
          open={open}
          duration={notificationDuration}
        />
      )}

      <NotificationContext.Provider
        value={{
          setMessage: handleSetMessage,
          closeNotification,
        }}
      >
        {children}
      </NotificationContext.Provider>
    </>
  );
};

export const useNotificationContext = () => {
  const context = useContext(NotificationContext);

  return context;
};
