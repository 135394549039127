import React from "react";

import { Alert, Snackbar } from "@mui/material";
import { useCallback } from "react";
import { INotificationProps } from "./models";

export const Notification: React.FC<INotificationProps> = ({
  closeNotification,
  open,
  message,
  type,
  notificationKey,
  duration = 6000,
}) => {
  const handleClose = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    closeNotification();
  };

  const handleExited = useCallback(() => {
    closeNotification();
  }, [closeNotification]);

  return (
    <Snackbar
      key={notificationKey ? notificationKey : undefined}
      open={open}
      autoHideDuration={duration}
      onClose={handleClose}
      TransitionProps={{ onExited: handleExited }}
    >
      <Alert severity={type} variant="filled" onClose={handleClose}>
        {message}
      </Alert>
    </Snackbar>
  );
};
