import axios from "axios";

export class MainApiService<T extends any> {
  route_path: string;

  constructor(rp: string) {
    this.route_path = rp;
  }

  create = (item: T) => {
    return axios.post(this.route_path, item);
  };

  //   getAll = (serchParams?: ISearchParams) => {
  //     return axios.get<ISearchReturn<T>>(this.route_path, {
  //       params: serchParams,
  //     });
  //   };

  getItemById = (id: string) => {
    return axios.get<T>(this.route_path + "/" + id);
  };

  updateItemById = ({ item, id }: { item: T; id: string }) => {
    return axios.put(this.route_path + "/" + id, item);
  };

  deleteItemById = (id: string) => {
    return axios.delete(this.route_path + "/" + id);
  };

  //   list = (params?: any) => {
  //     return axios.get<ISelectType[]>(this.route_path + "list", {
  //       params: params,
  //     });
  //   };
}

export default MainApiService;
