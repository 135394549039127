import { Box } from "@mui/material";
import Header from "../Components/Header/Header";
import AwardWinningForm from "../Components/Form/Form";
import Footer from "../Components/Footer/Footer";
import AcceptCookies from "../Components/UI/AcceptCookies/AcceptCookies";

const Home = () => {
  return (
    <Box>
      <Header />
      <Box
        height={"calc(100vh - 100px)"}
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <AwardWinningForm />
      </Box>
      <AcceptCookies />
      <Footer />
    </Box>
  );
};

export default Home;
