import { Link, Stack, Typography } from "@mui/material";

const Footer = () => {
  return (
    <Stack
      sx={(theme) => ({
        width: "100%",
        height: 50,
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        gap: 4,
        backgroundColor: theme.palette.grey["300"],
        position: "fixed",
        bottom: 0,
      })}
    >
      <Typography textTransform={"capitalize"}>Powered by</Typography>
      <Link href={"https://www.geralead.com.br"} target="_blank">
        <img
          src="/gera_lead_horizontal_logo.png"
          alt="Logo Gera Lead"
          width={200}
        />
      </Link>
    </Stack>
  );
};

export default Footer;
