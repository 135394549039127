import {
  Box,
  Link,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { TAwardWinningForm, awardWinningSchema } from "./FormSchema";
import AwardWinningFields from "./AwardWinningFields";
import { Form } from "../UI/Form";
import { useCallback, useEffect, useState } from "react";
import MainApiService from "../../services/mainApi.service";
import { useFetch } from "../../hooks/useFetch";
import { IGetPrizeDraw, IPrizeWinningStudy } from "../../models/prize-draw";
import { LinkNotFound, SuccessImage } from "../../utils/images";
import { useParams } from "react-router-dom";
import { Button } from "../UI/Button";

const PRIZE_DRAW_ROUTE =
  process.env.REACT_APP_API + "/api/prizeDraw/byPartialLink";
const prizeDrawService = new MainApiService<IGetPrizeDraw>(PRIZE_DRAW_ROUTE);

const PRIZE_WINNING_ROUTE =
  process.env.REACT_APP_API + "/api/prizeWinningStudy";
const prizeWinningService = new MainApiService<IPrizeWinningStudy>(
  PRIZE_WINNING_ROUTE
);

const AwardWinningForm = () => {
  const [prizeDraw, setPrizeDraw] = useState<IGetPrizeDraw>();
  const methods = useForm<TAwardWinningForm>({
    resolver: zodResolver(awardWinningSchema),
  });
  const [success, setSuccess] = useState(false);
  const { partialLink } = useParams();

  const { sendRequest: getPrizeDraw, error } = useFetch(
    prizeDrawService.getItemById
  );
  const { sendRequest, loading } = useFetch(prizeWinningService.create);

  const loadPrizeDraw = useCallback(async () => {
    if (partialLink) {
      const { data, success } = await getPrizeDraw(partialLink);
      if (data && success) {
        setPrizeDraw(data);
      }
    }
  }, [getPrizeDraw, partialLink]);

  useEffect(() => {
    loadPrizeDraw();
  }, [loadPrizeDraw]);

  const { handleSubmit, reset } = methods;

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));

  const onSubmit = useCallback(
    async (values: TAwardWinningForm) => {
      if (prizeDraw) {
        const { success } = await sendRequest({
          ...values,
          event_id: prizeDraw.event_id ? prizeDraw.event_id : "",
          establishment_id: prizeDraw.userConsultant.establishment_id,
          event_prize_draw_id: prizeDraw.id,
          user_consultant_id: prizeDraw.userConsultant.id,
          interest_course_code: values.interest_course_code,
          mail: values.mail,
          name: values.name,
          cell_phone_ddd: values.phone
            .replaceAll("(", "")
            .replaceAll(")", "")
            .substring(0, 2),
          cell_phone_number: values.phone
            .replaceAll("(", "")
            .replaceAll(")", "")
            .substring(2),
        });
        if (success) {
          setTimeout(reset, 100);
          setSuccess(true);
        }
      }
    },
    [sendRequest, prizeDraw, reset]
  );

  const err = error || !partialLink;

  return (
    <>
      {err && (
        <Box
          display={"flex"}
          flexDirection={"column"}
          alignItems={"center"}
          justifyContent={"center"}
        >
          <img
            style={{ width: "100%", maxWidth: 400, height: "auto" }}
            src={`data:image/svg+xml;base64,${LinkNotFound}`}
            alt="Imagem de sucesso"
          />
          <Typography variant="h5" textAlign={"center"} mt={5}>
            Ops, não conseguimos processar este link.
          </Typography>
          <Typography variant="subtitle1" textAlign={"center"}>
            Por favor verifique se digitou corretamente e tente novamente.
          </Typography>
        </Box>
      )}
      {success && (
        <Box
          sx={{
            paddingInline: matches ? 4 : 0,
            width: matches ? "100%" : "50%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img
            style={{ width: "100%", maxWidth: 800, height: "auto" }}
            src={`data:image/svg+xml;base64,${SuccessImage}`}
            alt="Imagem de sucesso"
          />
          <Typography variant="h4" textAlign={"center"}>
            Agradecemos sua participação!
          </Typography>
          <Button
            variant="contained"
            onClick={() => {
              setSuccess(false);
            }}
            sx={{ mt: 5 }}
          >
            Voltar ao início
          </Button>
        </Box>
      )}
      {!success && !err && (
        <Form {...methods}>
          <Box
            component="form"
            autoComplete="off"
            sx={{
              paddingInline: matches ? 4 : 0,
              width: matches ? "100%" : "50%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography variant="h6" mb={5} textAlign={"center"}>
              Cadastre-se e concorra a prêmios preenchendo os seus dados abaixo
            </Typography>
            <AwardWinningFields
              loading={loading}
              onClick={handleSubmit(onSubmit)}
            />
            <Stack mt={10} textAlign={"center"}>
              <Typography fontSize={17}>
                Ao concorrer você concorda com a nossa{" "}
                <Link
                  href={"https://www.geralead.com.br/privacy-policy"}
                  target={"_blanck"}
                  underline="none"
                >
                  Política de Privacidade.
                </Link>
              </Typography>
              <Typography fontSize={17}>
                Autorizo a coleta e uso dos meus dados, de acordo com a LGPD.
              </Typography>
            </Stack>
          </Box>
        </Form>
      )}
    </>
  );
};

export default AwardWinningForm;
