import "./App.css";
import { ThemeProvider, createTheme } from "@mui/material";
import themeOptions from "./theme";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import routes from "./router/routes";

const theme = createTheme(themeOptions());
const router = createBrowserRouter(routes);

function App() {
  return (
    <ThemeProvider theme={theme}>
      <RouterProvider router={router} />
    </ThemeProvider>
  );
}

export default App;
