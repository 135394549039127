import { z } from "zod";

export const awardWinningSchema = z.object({
  name: z.string().min(1, "O nome é obrigatório"),
  phone: z.string().min(1, "O telefone é obrigatório"),
  mail: z.string().optional(),
  interest_course_code: z.string({
    required_error: "O curso de interece é obrigatório",
  }),
});

export type TAwardWinningForm = z.infer<typeof awardWinningSchema>;
