import {
  Box,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";

interface IRadio {
  label: string;
  value: string;
}

interface IRadioGoupInputProps {
  name: string;
  defaultValue?: string;
  inline?: boolean;
  groupLabel: string;
  radios: IRadio[];
}

const RadioGroupInput = ({
  name,
  defaultValue,
  inline = false,
  radios,
  groupLabel,
}: IRadioGoupInputProps) => {
  const { control } = useFormContext();

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Controller
      render={({ fieldState: { error }, field }) => {
        const { onChange, value, ref } = field;
        return (
          <>
            <Box
              sx={(theme) => ({
                height: inline && !matches ? 40 : undefined,
                display: "flex",
                flexDirection: inline && !matches ? "row" : "column",
                alignItems: inline && !matches ? "center" : undefined,
                gap: inline && !matches ? 4 : 0,
                paddingBlock: 1,
                paddingInline: 2,
                border: `1px solid ${
                  error
                    ? `${theme.palette.error.main}`
                    : `${
                        theme.palette.mode === "light"
                          ? `${theme.palette.grey[400]}`
                          : `${theme.palette.grey[800]}`
                      }`
                }`,
                borderRadius: 1,
              })}
            >
              <FormLabel>{groupLabel}</FormLabel>
              <RadioGroup>
                <Stack
                  display={"flex"}
                  flexDirection={"row"}
                  justifyContent={"space-between"}
                  flexWrap={"wrap"}
                >
                  {radios.map((radio) => (
                    <FormControlLabel
                      key={radio.label}
                      value={radio.value}
                      control={
                        <Radio
                          size="small"
                          checked={value === radio.value && true}
                          onChange={onChange}
                        />
                      }
                      label={radio.label}
                      ref={ref}
                    />
                  ))}
                </Stack>
              </RadioGroup>
            </Box>
            <Typography
              variant="body2"
              fontSize={12}
              color={(theme) => theme.palette.error.main}
            >
              {error ? error?.message : ""}
            </Typography>
          </>
        );
      }}
      name={name}
      defaultValue={defaultValue}
      control={control}
    />
  );
};

export default RadioGroupInput;
