import { Snackbar } from "@mui/material";
import { useEffect, useState } from "react";
import { Button } from "../Button";

declare global {
  interface Window {
    dataLayer: any[];
    gtag?: (...args: any[]) => void;
  }
}

const AcceptCookies = () => {
  const [open, setOpen] = useState(false);
  const cookies = sessionStorage.getItem("cookies");

  useEffect(() => {
    if (!cookies) {
      setOpen(true);
    }

    if (cookies) {
      loadScript();
    }
  }, [cookies]);

  const loadScript = () => {
    const script = document.createElement("script");
    script.src = "https://www.googletagmanager.com/gtag/js?id=G-57J8EHM9N9";
    script.async = true;

    script.onload = function () {
      window.dataLayer = window.dataLayer || [];

      window.gtag = function () {
        window.dataLayer.push(arguments);
      };

      gtag("js", new Date());
      gtag("config", "G-57J8EHM9N9");
    };

    document.head.appendChild(script);
  };

  const acceptCookies = () => {
    if (!sessionStorage.getItem("cookies")) {
      sessionStorage.setItem("cookies", "true");
      setOpen(false);
    }
  };

  const action = (
    <>
      <Button
        color="primary"
        size="small"
        onClick={acceptCookies}
        sx={{ textTransform: "uppercase" }}
      >
        Aceitar cookies
      </Button>
    </>
  );

  return (
    <Snackbar
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      open={open}
      sx={{ width: "100%", backgroundColor: "white" }}
      message="Utilizamos cookies para oferecer melhor experiência e melhorar o desempenho. Ao utilizar este site, você concorda com o uso de cookies."
      action={action}
    />
  );
};

export default AcceptCookies;
