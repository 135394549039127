import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";

const Header = () => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box
      sx={(theme) => ({
        height: 50,
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: theme.palette.primary.dark,
        color: "#fff",
      })}
    >
      <Typography
        variant="h5"
        fontWeight={"bold"}
        textTransform={"uppercase"}
        textAlign={"center"}
        sx={{ fontSize: matches ? 20 : undefined }}
      >
        Concorra a prêmios exclusivos
      </Typography>
    </Box>
  );
};

export default Header;
