import { Grid } from "@mui/material";
import { FormInput } from "../UI/FormTextInput";
import RadioGroupInput from "../UI/RadioGroupInput";
import { Button } from "../UI/Button";

const AwardWinningFields = ({
  loading,
  onClick,
}: {
  loading: boolean;
  onClick: () => void;
}) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12} mb={1}>
        <FormInput name="name" label="Nome" fullWidth size="small" autoFocus />
      </Grid>
      <Grid item xs={12} sm={6} mb={1}>
        <FormInput
          name="phone"
          label="Telefone"
          fullWidth
          size="small"
          mask={"(00)000000000"}
        />
      </Grid>
      <Grid item xs={12} sm={6} mb={1}>
        <FormInput name="mail" label="E-mail" fullWidth size="small" />
      </Grid>
      <Grid item xs={12} sm={12} mb={1}>
        <RadioGroupInput
          groupLabel="Qual formato de graduação você procura?"
          name="interest_course_code"
          radios={[
            { label: "Graduação", value: "1" },
            { label: "Pós-Graduação", value: "2" },
          ]}
          defaultValue="1"
          inline
        />
      </Grid>
      <Grid item xs={12} sm={12} mb={1} textAlign={"end"}>
        <Button loading={loading} variant="contained" onClick={onClick}>
          Quero conhecer
        </Button>
      </Grid>
    </Grid>
  );
};

export default AwardWinningFields;
